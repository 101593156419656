<template>
  <v-container style="max-width: 800px" class="p-0">
    <div v-if="type_account == 'sandbox'" id="sandbox">Sandbox</div>
    <v-row class="text-center">
      <v-col md="6" offset-md="3">
        <v-card
          height="500"
          width="500"
          v-show="action == 'loading' && !error_message"
        >
          <v-sheet color="grey lighten-4" height="500" class="pa-3">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, image, table-tfoot"
            ></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <!-- error card -->
        <v-card
          elevation="15"
          v-show="!action"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-show="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-show="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>
          <v-btn
            fab
            absolute
            top
            x-small
            right
            depressed
            color="#b04647"
            class="text-white"
            v-show="!isLoading && show_close"
            @click="callParentFunction"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text v-show="error_message">
            <div
              style="
                font-weight: 800;
                color: #b04647;
                margin-top: 100px;
                margin-bottom: 50px;
              "
            >
              <h4 class="mx-auto">{{ error_message }}</h4>
            </div>
            <div style="font-weight: 700">
              <v-icon color="white" size="100px">
                mdi-emoticon-sad-outline
              </v-icon>
            </div>
          </v-card-text>

          <v-card-text>
            <div class="white--text">
              <h6 v-show="!error_message" class="mx-auto">
                {{ $t('title.totalmount') }}
              </h6>
            </div>
            <div style="font-weight: 700; color: #b04647">
              <v-progress-circular
                indeterminate
                color="#b04647"
                size="16"
                width="2"
                v-show="isLoadingPercent"
              ></v-progress-circular>
              <h4 v-show="!error_message && !isLoadingPercent" class="mx-auto">
                {{ amount }} FCFA
              </h4>
            </div>
          </v-card-text>
          <v-toolbar v-show="!error_message" color="transparent" flat>
            <div
              class="mx-auto white--text text-subtitle-1"
              style="
                width: 100% !important;
                background-color: #b04647 !important;
                font-size: 12px !important;
                font-weight: 700 !important;
              "
              v-show="!isLoadingPercent"
            >
              {{ percent }}% {{ $t('title.transaction') }}
            </div>
            <template v-slot:extension>
              <v-tabs
                grow
                slider-color="#B04647"
                slider-size="5"
                v-model="tabs"
                centered
                icons-and-text
              >
                <v-tab
                  grow
                  v-show="code == 'mobile' || code == null"
                  :disabled="code == 'card' || isLoading"
                  class="white--text"
                >
                  Mobile Money
                </v-tab>

                <v-tab
                  grow
                  v-show="code == 'card' || code == null"
                  :disabled="code == 'mobile' || isLoading"
                  class="white--text"
                >
                  {{ $t('btn.card') }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-tabs-items
            v-show="!error_message"
            id="tab"
            class="cardColor"
            v-model="tabs"
            :touchless="true"
          >
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-text>
                  <v-form :eager="true" ref="form">
                    <v-container class="p-6">
                      <v-row>
                        <v-col class="pb-0" cols="12" sm="6">
                          <v-text-field
                            class="pb-0"
                            clearable
                            ref="iu"
                            color="grey"
                            v-model="firstname"
                            :label="$t('fields.firstname')"
                            filled
                            @blur="$v.firstname.$touch()"
                            :error-messages="firstnameErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col class="pb-0" cols="12" sm="6">
                          <v-text-field
                            class="pb-0"
                            clearable
                            color="grey"
                            v-model="lastname"
                            :label="$t('fields.lastname')"
                            filled
                            @blur="$v.lastname.$touch()"
                            :error-messages="lastnameErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col class="pb-0" cols="12" sm="12">
                          <v-text-field
                            class="pb-0"
                            color="grey"
                            v-model="email"
                            @space.prevent
                            filled
                            label="Email"
                            prepend-inner-icon="mdi-email"
                            clearable
                            @blur="$v.email.$touch()"
                            :error-messages="emailErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col class="pb-0 pt-1" cols="4" sm="4">
                          <v-select
                            class="pb-0"
                            filled
                            v-model="indicatif"
                            :items="available_indicatif"
                            item-value="code"
                            item-text="code"
                           
                          >
                            <template v-slot:item="slotProps">
                              <flag
                                style="border-radius: 2px; font-size: 18px"
                                :iso="slotProps.item.flag"
                                class="mr-2 white-text"
                              />
                              <span style="font-size: 12px">{{
                                slotProps.item.code
                              }}</span>
                            </template>
                            <template v-slot:selection="slotProps">
                              <flag
                                style="border-radius: 2px; font-size: 18px"
                                :iso="slotProps.item.flag"
                                class="mr-2 white-text"
                              />
                              <span style="font-size: 12px">
                                {{ slotProps.item.code }}</span
                              >
                            </template>
                          </v-select>
                        </v-col>

                        <v-col class="pb-0 pt-1" cols="8" sm="8">
                          <v-text-field
                            class="pb-0"
                            color="grey"
                            filled
                            v-model="phonenumber"
                            :label="$t('fields.phonenumber')"
                            @keypress="filter($event)"
                            :maxlength="maxNumber"
                            @blur="$v.phonenumber.$touch()"
                            @paste.prevent
                            :error-messages="phonenumberErrors"
                          >
                          <template v-slot:append>
                            <v-fade-transition leave-absolute>
                              <img
                                v-if="img_src"
                                width="24"
                                height="24"
                                :src= img_src
                                alt=""
                              >
                              <v-icon
                              v-else
                                left
                              >
                                mdi-phone
                              </v-icon>
        
                            </v-fade-transition>
                          </template>
                          </v-text-field>
                        </v-col>

                        <v-col class="pb-0" cols="12" sm="12">
                          <div
                            class="pb-0"
                            style="color: red"
                            ref="carderror"
                          ></div>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-btn
                            height="40"
                            block
                            depressed
                            class="text-white pay-btn mx-auto"
                            @click="paymentMobile_submit"
                            :loading="isLoading"
                            :disabled="can_submit"
                          >
                            {{ $t('btn.checkout') }} {{ amount }} FCFA
                            <v-icon class="ml-auto" dark fab right>
                              mdi-lock
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-text>
                  <v-form :eager="true" ref="cardform">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" class="pb-0">
                          <v-text-field
                            class="pb-0"
                            clearable
                            color="grey"
                            v-model="firstname"
                            :label="$t('fields.firstname')"
                            filled
                            @blur="$v.firstname.$touch()"
                            :error-messages="firstnameErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" class="pb-0">
                          <v-text-field
                            class="pb-0"
                            clearable
                            color="grey"
                            v-model="lastname"
                            :label="$t('fields.lastname')"
                            filled
                            @blur="$v.lastname.$touch()"
                            :error-messages="lastnameErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" class="pb-0">
                          <v-text-field
                            class="pb-0"
                            color="grey"
                            v-model="email"
                            filled
                            label="Email"
                            prepend-inner-icon="mdi-email"
                            clearable
                            @blur="$v.email.$touch()"
                            :error-messages="emailErrors"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" class="pb-0">
                          <div
                            id="card-number"
                            class="form-control"
                            style="width: 100% !important"
                          ></div>
                        </v-col>
                        <v-col cols="6" sm="6" class="pb-0">
                          <div
                            class="form-control"
                            id="card-expiry"
                            style="width: 100% !important"
                          ></div>
                        </v-col>
                        <v-col cols="6" sm="6" class="pb-0">
                          <div
                            id="card-cvc"
                            class="form-control"
                            style="width: 100% !important"
                          ></div>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <div style="color: red" ref="carderror"></div>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-btn
                            height="40"
                            block
                            depressed
                            class="text-white pay-btn mx-auto"
                            @click="paymentCard_submit"
                            :loading="isLoading"
                            :disabled="can_submit"
                          >
                            {{ $t('btn.checkout') }} {{ amount }} FCFA
                            <v-icon class="ml-auto" dark fab right>
                              mdi-lock
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions
            class=""
            style="font-size: 11px; display: block; font-weight: 500"
          >
            <v-spacer></v-spacer>
            <div class="white--text mx-auto" style="margin: 8px">
              {{ $t('title.bottom_text4') }}
              <v-icon style="font-size: 18px; color: #b04647">
                mdi-shield-check-outline</v-icon
              >
            </div>
          </v-card-actions>
        </v-card>

        <!-- chargement card -->
        <v-card
          v-show="action == 'waiting'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-show="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-show="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-card-text>
            <div style="margin-top: 40px" class="white--text">
              <h6 class="mx-auto">
                {{ $t('title.totalmount') }}
              </h6>
            </div>
            <div style="font-weight: 700; color: #b04647">
              <h4 class="mx-auto">{{ amount }} FCFA</h4>
            </div>
            <v-img
              style="margin-top: 10px"
              height="150"
              contain
              :src="hand_img"
            ></v-img>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 10px;
                line-height: 2 !important;
              "
            >
              <p class="mx-auto">
                {{ $t('title.help1') }}
              </p>
            </div>
            <div
              style="
                font-size: 20px;
                font-weight: bold;
                color: #b04647;
                line-height: 2 !important;
              "
            >
              <p
                v-show="heplconfimation"
                style="
                  background-color: rgb(176, 70, 71, 0.3);
                  border-radius: 5px;
                "
                class="mx-auto"
              >
                {{ $t('title.help3') + heplconfimation }} 
              </p>
              
            </div>

            <div
              style="
                font-weight: 700;
                color: #fff;
                margin-top: 5px;
                margin-bottom: 5px;
                line-height: 2.5;
              "
            >
              <v-progress-circular
                :size="30"
                :width="2"
                color="rgb(176, 70, 71, 1);"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
          <v-card-actions
            class=""
            style="font-size: 11px; display: block; font-weight: 500"
          >
            <v-spacer></v-spacer>
            <div class="white--text mx-auto" style="margin: 8px">
              {{ $t('title.bottom_text4') }}
              <v-icon style="font-size: 18px; color: #b04647">
                mdi-shield-check-outline</v-icon
              >
            </div>
          </v-card-actions>
        </v-card>

        <!-- completed card -->
        <v-card
          v-show="action == 'completed'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-show="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-show="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-card-text>
            <div
              style="font-weight: 700; margin-top: 50px; margin-bottom: 30px"
            >
              <v-icon color="white" size="45px"> mdi-check-circle</v-icon>
            </div>

            <div
              style="
                font-weight: 900;
                color: white;
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 18px;
              "
            >
              {{ $t('title.success_payment') }}
            </div>

            <div
              v-if="personalized_message != null"
              style="
                font-weight: 400;
                color: #ffff;
                margin-top: 5px;
                margin-bottom: 15px;
                line-height: 2 !important;
                font-size: 12px;
              "
            >
              {{ personalized_message }}
            </div>

            <v-btn
              outlined
              @click="finish_click()"
              class="text-white"
              color="success"
            >
              {{ $t('btn.terminate') }}
            </v-btn>
          </v-card-text>
          <v-card-actions
            class=""
            style="font-size: 11px; display: block; font-weight: 500"
          >
            <v-spacer></v-spacer>
            <div class="white--text mx-auto" style="margin: 8px">
              {{ $t('title.bottom_text4') }}
              <v-icon style="font-size: 18px; color: #b04647">
                mdi-shield-check-outline</v-icon
              >
            </div>
          </v-card-actions>
        </v-card>

        <v-card
          v-show="action == 'failed'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-show="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-show="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-btn
            fab
            absolute
            top
            small
            right
            depressed
            class="text-white close-btn"
            v-show="!isLoading && show_close"
            @click="callParentFunction"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>

          <v-card-text>
            <div
              style="font-weight: 700; margin-top: 50px; margin-bottom: 30px"
            >
              <v-icon color="#b04647" size="80px"> fa-solid fa-xmark </v-icon>
            </div>
            <div
              style="
                font-weight: 900;
                color: white;
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 18px;
              "
            >
              {{ $t('title.failed_payment') }}
            </div>

            <div
              v-if="message_failed_payment"
              style="
                font-weight: 900;
                color: #b04647;
                margin-top: 3px;
                margin-bottom: 3px;
                font-size: 15px;
              "
            >
              {{ message_failed_payment }}
            </div>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 30px;
                line-height: 2 !important;
                font-size: 20px;
              "
            ></div>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 30px;
                line-height: 2 !important;
                font-size: 20px;
              "
            >
              <v-btn
                outlined
                @click="try_click"
                class="text-white"
                color="warning"
                v-show="btn_try"
              >
                {{ $t('btn.try') }}
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions
            class=""
            style="font-size: 11px; display: block; font-weight: 500"
          >
            <v-spacer></v-spacer>
            <div class="white--text mx-auto" style="margin: 8px">
              {{ $t('title.bottom_text4') }}
              <v-icon style="font-size: 18px; color: #b04647">
                mdi-shield-check-outline</v-icon
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import i18n from '../i18n';
import { mapGetters } from 'vuex';
import 'core-js/actual/array/group';
import mtn_logo from '../assets/mtn.svg';
import moov_logo from '../assets/moov.svg';
import { validationMixin } from 'vuelidate';
import waiting_img from '../assets/waiting.png';
import { required, minLength, email, helpers } from 'vuelidate/lib/validators';

const nameRegex = helpers.regex(
  'alphaNum',
  /^(?![0-9]+$)[A-Za-z0-9_ -]{1,30}$/
);

var stripe = Stripe('pk_test_oKhSR5nslBRnBZpjO6KuzZeX');

var elements = stripe.elements({
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
    },
  ],
  locale: i18n.locale,
});

export default {
  name: 'ModalPayment',
  mixins: [validationMixin],
  validations: {
    firstname: { required, nameRegex },
    lastname: { required, nameRegex },
    email: { required, email },
    indicatif: { required },
    phonenumber: {
      required,
      minLength: minLength(8),
    },
  },

  data() {
    return {
      countIntervalMoov: 10,
      countries_items: [
        {
          code: '229',
          flag: 'bj',
        },
        {
          code: '228',
          flag: 'tg',
        },
        {
          code: '225',
          flag: 'ci',
        },
      ],
      personalized_message: null,
      maxNumber: 8,
      indicatif: '229',
      operation_response: null,
      action: 'loading',
      network: null,
      hand_img: waiting_img,
      client_secret: null,
      elements: null,
      help_message: null,
      code: 'mobile',
      error_message: null,
      show_close: true,
      error: false,
      token: null,
      cardExpiry: null,
      cardCvc: null,
      show: true,
      amount: null,
      amount_card: null,
      amount_mobile: null,
      isLoading: false,
      isLoadingPercent: false,
      cvcnumber: null,
      img_src: null,
      mtn_src: mtn_logo,
      moov_src: moov_logo,
      expi: false,
      icon: 'mdi-card',
      cardNumber: null,
      heplconfimation : null,
      email: null,
      firstname: null,
      lastname: null,
      phonenumber: null,
      tabs: null,
      idTransact: null,
      description: null,
      return_url_integrator: null,
      message_failed_payment: null,
      percent: '2',
      type_account: null,
      paymentResponse: {},
      available_indicatif: [],
      available_prefix: [],
      timer: null,
      btn_try: true,
      elementStyles: {
        base: {
          fontSize: '16px',
          color: '#495057',
          fontFamily:
            'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
          fontWeight: '500',
          fontSmoothing: 'antialiased',
          fontStyle: 'normal',
          '::placeholder': {
            color: '#4A4649',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#4A4649',
          },
        },
      },
      elementClasses: {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      },
    };
  },

  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
    this.cancelAutoCall();
  },
  created() {
    this.getPrefix();
    this.token = this.$route.params.token;
    this.code = this.$route.params.vue;
    this.show_close = this.$route.params.cloosebtn ? false : true;
    // console.log(["dsds",this.$route.params.vue, this.show_close])
  },
  mounted() {
    if (this.code == 'notimes') {
      this.error_message = i18n.t('title.error');
    }

    if (this.code == 'mobile') {
      this.tabs = 0;
      this.percent = '2';
    } else if (this.code == 'card') {
      this.tabs = 1;
      this.percent = '3';
    }

    this.getTransactionToken();

    this.cardNumber = elements.create('cardNumber', {
      showIcon: true,
      style: this.elementStyles,
      classes: this.elementClasses,
    });

    this.cardExpiry = elements.create('cardExpiry', {
      style: this.elementStyles,
      classes: this.elementClasses,
    });

    this.cardCvc = elements.create('cardCvc', {
      style: this.elementStyles,
      classes: this.elementClasses,
    });

    if (!this.token) {
      this.error_message = i18n.t('title.error');
    }

    if (!this.error_message) {
      try {
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc.mount('#card-cvc');
        this.cardNumber.mount('#card-number');
      } catch (error) {}
    }
  },

  computed: {
    can_submit() {
      if (this.tabs == 0) {
        if (
          this.$v.firstname.$invalid ||
          this.$v.lastname.$invalid ||
          this.$v.phonenumber.$invalid ||
          this.$v.indicatif.$invalid ||
          this.$v.email.$invalid ||
          !this.getNetworknumber(
            this.phonenumber.toString().replace(/\s+/g, '').substring(0, 2)
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.$v.firstname.$invalid ||
          this.$v.lastname.$invalid ||
          this.$v.email.$invalid ||
          this.amount <= 320
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.required &&
        errors.push(i18n.t('validations.required_firstname'));
      !this.$v.firstname.nameRegex &&
        errors.push(i18n.t('validations.caracter_firstname'));
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastname.$dirty) return errors;
      !this.$v.lastname.required &&
        errors.push(i18n.t('validations.required_lastname'));
      !this.$v.lastname.nameRegex &&
        errors.push(i18n.t('validations.caracter_lastname'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(i18n.t('validations.valid_email'));
      !this.$v.email.required &&
        errors.push(i18n.t('validations.required_email'));
      return errors;
    },
    phonenumberErrors() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required &&
        errors.push(i18n.t('validations.required_phonenumber'));
      if (this.phonenumber) {
        !this.getNetworknumber(
          this.phonenumber.toString().replace(/\s+/g, '').substring(0, 2)
        ) && errors.push(i18n.t('validations.network_phonenumber'));
      }
      !this.$v.phonenumber.minLength &&
        errors.push(i18n.t('validations.min_phonenumber'));
      if (!this.$v.indicatif.required) {
        errors.push(i18n.t('validations.required_indicatif'));
      }
      return errors;
    },

    ...mapGetters(['langLocal', 'listPrefix', 'selectList']),
  },
  watch: {
    email(val) {
      if (val) {
        this.email = val.trim();
      }
    },
    indicatif(value) {
      this.maxNumber = 8;
      this.phonenumber = null;
      if (value) {
        this.available_prefix = this.listPrefix.filter(
          (element) => element.indicatif == value
        );
        this.getFees();
      }
    },

    operation_response(val) {
      if (val.success == true && val.message == 'waiting_validated') {
        this.isLoading = true;
        this.action = 'waiting';
      }

      if (
        val.success == true &&
        this.network == 'moov' &&
        val.message == 'PENDING'
      ) {
        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
          console.log(this.countIntervalMoov);
          this.countIntervalMoov--;
          if (this.countIntervalMoov === 0) {
            this.cancelAutoCall();
          }
          this.getCallBackMoov();
        }, 4000);
      }

      if (val.success == true && val.statusTransaction == 'SUCCESSFUL') {
        console.log('Here put moov');
        this.cancelAutoCall();
        this.personalized_message = val.personalizedMessage;
        this.isLoading = false;
        this.action = 'completed';
        if (this.return_url_integrator != null) {
          let element_return_url = this.hasQueryParams(
            this.return_url_integrator
          )
            ? this.return_url_integrator +
              '&transaction=' +
              encodeURIComponent(JSON.stringify(val))
            : this.return_url_integrator +
              '?transaction=' +
              encodeURIComponent(JSON.stringify(val));
          window.parent.postMessage(
            {
              success: true,
              redirect: true,
              redirect_url: this.return_url_integrator + element_return_url,
            },
            '*'
          );
          window.location.replace(element_return_url);
        } else {
          window.parent.postMessage(
            {
              success: true,
              redirect: false,
              transaction: val,
            },
            '*'
          );
        }
      }

      if (val.success == false) {
        this.cancelAutoCall();
        this.isLoading = false;
        this.action = 'failed';
        this.btn_try = val.btnTry;
        this.message_failed_payment = val.message;
      }
    },
    phonenumber(val) {
      if (val) {
        if (
          this.getNetworknumber(val.substring(0, 2), this.indicatif) != null
        ) {
          let getresaux = null;
          getresaux = this.getNetworknumber(
            val.substring(0, 2),
            this.indicatif
          );

          if(getresaux.length > 0){
            this.network = getresaux[0].name
            this.img_src = getresaux[0].logo
            this.heplconfimation =  getresaux[0].codeConfirmation
          }
          if(getresaux.length == 0){
            this.img_src = null
            this.heplconfimation =  null
          }
        }
      } else {
        this.img_src = null;
      }
    },
    langLocal(val) {
      if (this.error_message) {
        this.error_message = i18n.t('title.error');
        elements = stripe.elements({ locale: i18n.locale });
      }
    },
    tabs() {
      if (this.tabs == 1) this.percent = '3';
      this.amount = this.tabs == 0 ? this.amount_mobile : this.amount_card;
    },
  },

  methods: {
    async getPrefix() {
      await this.$store
        .dispatch('getPrefix')
        .then(() => {
          this.available_prefix = this.listPrefix.filter(
            (element) => element.indicatif == this.indicatif
          );

          this.available_indicatif = this.listPrefix.reduce(
            (previous, current) => {
              if (
                previous.some(
                  (o) => o.flag == current.indicatif && o.code == current.code
                )
              )
                return previous;
              return [
                ...previous,
                {
                  code: current.indicatif,
                  flag: current.code,
                },
              ];
            },
            []
          );
        })
        .catch((error) => {
          return { error: true };
        });
    },
    cancelAutoCall() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    async getCallBackMoov() {
      const data = new FormData();
      data.append('token', this.token);
      data.append('numbretimes', this.countIntervalMoov);
      await this.$store
        .dispatch('getCallBackMoov', data)
        .then(() => {})
        .catch((error) => {
          return { error: true };
        });
    },

    async getFees() {
      this.isLoadingPercent = true;
      const formdata = new FormData();
      formdata.append('token', this.token);
      formdata.append('indicatif', this.indicatif);
      formdata.append('network', this.network);
      let feesinfoToken = await this.$store.dispatch('getFees', formdata);

      if (feesinfoToken.success) {
        this.isLoadingPercent = false;
        this.percent = feesinfoToken.data.feesMobile;
        this.idTransact = feesinfoToken.data.initTransactionId;
        this.amount = this.amount_mobile = feesinfoToken.data.amountMobile;
        this.amount_card = feesinfoToken.data.amountCard;
      }
    },

    try_click() {
      this.action = null;
      this.isLoading = false;
      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCvc.destroy();
      this.message_failed_payment = null;
      this.countIntervalMoov = 10;
      this.operation_response = {};

      this.cardNumber = elements.create('cardNumber', {
        showIcon: true,
        style: this.elementStyles,
        classes: this.elementClasses,
      });

      this.cardExpiry = elements.create('cardExpiry', {
        style: this.elementStyles,
        classes: this.elementClasses,
      });

      this.cardCvc = elements.create('cardCvc', {
        style: this.elementStyles,
        classes: this.elementClasses,
      });

      this.cardExpiry.mount('#card-expiry');
      this.cardCvc.mount('#card-cvc');
      this.cardNumber.mount('#card-number');
    },

    finish_click() {
      window.parent.postMessage(
        {
          success: true,
          redirect: false,
          transaction: this.operation_response,
        },
        '*'
      );
    },

    callParentFunction() {
      window.parent.postMessage(
        {
          success: null,
          secured_id: 'secured_id_w9r4b8mvk',
          close: true,
          closeWidget: { success: false, reason: 'DIALOG DISMISSED' },
        },
        '*'
      );
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    hasQueryParams(url) {
      return url.indexOf('?') !== -1;
    },

    async initialize() {
      const formdata = new FormData();
      formdata.append('token', this.token);
      formdata.append('description', this.description);
      await axios.get('sanctum/csrf-cookie');
      const { clientSecret } = await this.$store.dispatch(
        'initializePayment',
        formdata
      );

      this.elements = stripe.elements({ clientSecret, locale: i18n.locale });
      this.client_secret = clientSecret;
    },

    async showMessage(messageText) {
      const messageContainer = this.$refs.carderror;
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.textContent = '';
      }, 25000);
    },

    async getTransactionToken() {
      if (this.token) {
        let paymentInfo = await this.$store.dispatch(
          'getPaymentInfo',
          this.token
        );
        if (paymentInfo.success) {
          this.type_account = paymentInfo.data.compte;
          this.firstname = paymentInfo.data.firstname;
          this.$v.firstname.$touch();
          this.lastname = paymentInfo.data.lastname;
          this.$v.lastname.$touch();
          this.email = paymentInfo.data.email;
          this.$v.email.$touch();
          this.amount = this.amount_mobile = paymentInfo.data.amountMobile;
          this.amount_card = paymentInfo.data.amountCard;
          this.idTransact = paymentInfo.data.transactionId;
          this.description = paymentInfo.data.descriptionInitial;
          this.return_url_integrator = paymentInfo.data.backLink;

          window.Echo.channel(
            `mpay_event_transaction.${this.idTransact}`
          ).listen('.mpay_event_transaction', (e) => {
            console.log(e);
            this.operation_response = e.data;
          });

          this.action = null;

          if (this.code == 'card') {
            this.amount = this.amount_card;
          } else if (this.code == 'mobile') {
            this.amount = this.amount_mobile;
          }

          if (this.amount_card < 320) {
            if (this.code == null) {
              this.code = 'mobile';
              this.tabs = 0;
            } else if (this.code == 'card') {
              this.error_message = i18n.t('title.error');
            }
          } else {
            this.initialize();
          }
        } else {
          this.error_message = i18n.t('title.invalid_token');
          this.action = null;
        }
      }
    },

    async paymentMobile_submit() {
      this.isLoading = true;

      if (
        !this.$v.firstname.$invalid &&
        !this.$v.lastname.$invalid &&
        !this.$v.email.$invalid &&
        !this.$v.phonenumber.$invalid
      ) {
        const data = new FormData();
        data.append('firstname', this.firstname);
        data.append('lastname', this.lastname);
        data.append('email', this.email);
        data.append('network', this.network);
        data.append('indicatif', this.indicatif);
        data.append('token', this.token);
        let phone = this.phonenumber.toString().replace(/\s+/g, '');
        data.append('phonenumber', this.indicatif.concat('', phone));
        this.action = 'waiting';

        await this.$store
          .dispatch('mobilePayment', data)
          .then(() => {})
          .catch((error) => {
            return { error: true };
          });
      }
    },

    async paymentCard_submit() {
      this.showMessage('');
      this.isLoading = true;
      if (
        !this.$v.firstname.$invalid &&
        !this.$v.lastname.$invalid &&
        !this.$v.email.$invalid
      ) {
        const card = elements.getElement('cardNumber');
        let response = await stripe
          .confirmCardPayment(this.client_secret, {
            payment_method: {
              card: card,
              billing_details: {
                name: this.lastname + ' ' + this.firstname,
                email: this.email,
              },
            },
          })
          .then(function (result) {
            return result;
          });

        if (response.error) {
          this.isLoading = false;
          this.showMessage(response.error.message);
        } else {
          this.showMessage('');
          const formdata = new FormData();
          formdata.append('lastname', this.lastname);
          formdata.append('firstname', this.firstname);
          formdata.append('email', this.email);
          formdata.append('token', this.token);
          formdata.append(
            'paymentIntent',
            JSON.stringify(response.paymentIntent)
          );

          await this.$store
            .dispatch('confirmpayment', formdata)
            .then((response) => {})
            .catch((error) => {
              return { error: true };
            });
        }
      }
    },

    filter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect.split(' ').join(''))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    expi_save(newdate) {
      this.$refs.expi.save(newdate);
    },
    getNetworknumber(prefix, indicatif) {
      let name = null;
      let instance = this.selectList.filter(
        (element) => element.indicatif == indicatif && element.prefix == prefix
      );
      console.log(indicatif,prefix,instance)
      name = instance ?? instance[0].name;
      return name;
    },

    changeLanguage() {
      if (this.langLocal == 'fr') this.$store.dispatch('setlanguage', 'en');
      else this.$store.dispatch('setlanguage', 'fr');
    },
  },
};
</script>

<style>
.StripeElement {
  padding: 10px !important;
}

/* Can't see what I type without this */
#card-number.form-control,
#card-cvc.form-control,
#card-exp.form-control {
  display: inline-block;
}

.container {
  --bs-gutter-x: 0.6rem !important;
}

.v #custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}

.divider {
  border-color: white !important;
}

.cardColor {
  background: rgba(255, 255, 255, 0.3) !important;
  border-color: white !important;
}

.pay-btn {
  background: linear-gradient(90deg, #102e49, #b04647);
  height: 40px;
}

.try-btn {
  background: #b04647 !important;
  height: 40px;
}

.close-btn {
  background: #b04647 !important;
  margin-top: 25px;
  margin-right: -10px;
  border-radius: 100%;
}

.pay-btn:hover {
  background: linear-gradient(180deg, #102e49, #b04647);
}

.v-toolbar__content {
  max-height: 25px !important;
}

.v-toolbar--extended {
  max-height: 72px !important;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0.8rem 0.55rem;
}

.v-toolbar__content {
  padding: 0px !important;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}

.v-tab {
  font-size: 0.7rem !important;
}

#sandbox {
  float: left;
  color: white;
  margin-left: 50px !important;
  background-color: #ff8c00;
  padding: 4px;
  position: fixed;
  border-radius: 2px;
  top: 0;
  left: 0;
}

@media (max-width: 700px) {
  #sandbox {
    float: left;
    color: white;
    background-color: #ff8c00;
    margin-left: 2px !important;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px !important;
}

.pay-btn {
  background: linear-gradient(273deg, #102e49, #b04647) !important;
}
</style>
