import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    langLocal: 'fr',
    listPrefix: null,
    selectList: null,
  },
  getters: {
    langLocal(state) {
      return state.langLocal;
    },
    listPrefix(state) {
      return state.listPrefix;
    },
    selectList(state) {
      return state.selectList;
    },
  },
  mutations: {
    SET_LANGLOCAL(state, value) {
      state.langLocal = value;
      i18n.locale = state.langLocal;
    },
    SET_LISTPREFIX(state, value) {
      state.listPrefix = value;
    },
    SET_SELECTLIST(state, value) {
      state.selectList = value;
    },
  },
  actions: {
    async setlanguage({ commit }, payload) {
      commit('SET_LANGLOCAL', payload);
    },

    async confirmpayment({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/card-payment', payload);
        return response;
      } catch (e) {
        
      }
    },

    async getPaymentInfo({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        const formdata = new FormData();
        formdata.append('token', payload);
        let response = await axios.post('api/v1/infos-token', formdata);
        return response.data;
      } catch (e) {
        
      }
    },

    async getPaymentPageInfo({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        const formdata = new FormData();
        formdata.append('token', payload);
        let response = await axios.post('api/v1/infos-page', formdata);
        return response.data;
      } catch (e) {
        
      }
    },

    async getTokenPayment({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/token-payment', payload);
        return response.data;
      } catch (e) {
        
      }
    },
    async mobilePayment({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/mobile-payment', payload);
        return response;
      } catch (e) {
        
      }
    },

    // async pagePayment({ commit }, payload) {
    //   await axios.get('sanctum/crf-cookie');
    //   try {
    //     let response = await axios.post('api/v1/getinfoTokenPage');
    //     return response.data;
    //   } catch (e) {
        
    //   }
    // },

    async initializePayment({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/card-payment-intement', payload);
        return response.data;
      } catch (e) {
       
      }
    },
    async getPrefix({ commit }) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/prefix');
        let list = response.data;
        let result = [];
        let indexforEachPrefix = 0;
        if (list) {
          list.forEach((item, index) => {
            item.prefix.forEach((itemPrefix, indexPrefix) => {
              result[indexforEachPrefix] = {
                name: item.name,
                indicatif: item.indicatif,
                prefix: itemPrefix,
                logo: item.logo,
                codeConfirmation: item.code_confirmation
              };
              indexforEachPrefix++;
            });
          });

          commit('SET_LISTPREFIX', response.data);
          commit('SET_SELECTLIST', result);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCustomerInfo({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        const formdata = new FormData();
        formdata.append('token', payload);
        let response = await axios.post('api/v1/mpay-receipt', formdata);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getCallBackMoov({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post(
          'api/v1/mpay-callback-moovmoney',
          payload
        );
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },

    async getFees({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/v1/fees', payload);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  plugins: [createPersistedState()],
  modules: {},
});
