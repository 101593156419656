<template>
  <v-container
    v-bind:style="[
      mounted && type_account == 'sandbox'
        ? { border: '6px solid #ff8c00' }
        : '',
    ]"
    fill-height
    style="max-width: 800px"
    class="p-8"
  >
    <div v-if="type_account == 'sandbox'" id="sandbox">Sandbox</div>
    <v-row class="text-center" justify="center">
      <v-col md="6">
        <!-- error card -->
        <v-card style="background-color: #102e49" width="500">
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-show="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-show="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>
          <v-card-text>
            <div
              style="
                font-weight: 800;
                color: #b04647;
                margin-top: 100px;
                margin-bottom: 50px;
              "
            >
              <h6 class="mx-auto">{{ $t("title.notfound_message") }}</h6>
              <div style="font-weight: 700">
                <v-icon color="white" size="80spx"
                  >mdi-emoticon-sad-outline
                </v-icon>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../i18n";

export default {
  name: "Payment",

  data() {
    return {
      error_message: null,
      error: false,
      type_account: process.env.VUE_APP_MPAY_ENV_MODE,
      mounted: false,
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_MPAY_ENV_MODE)
    this.mounted = true;
  },
  watch: {},
  computed: {
    ...mapGetters(["langLocal"]),
  },
  methods: {
    callParentFunction() {
      window.parent.postMessage(
        {
          success: null,
          secured_id: "secured_id_w9r4b8mvk",
          close: true,
          closeWidget: { success: false, reason: "DIALOG DISMISSED" },
        },
        "*"
      );
    },

    changeLanguage() {
      if (this.langLocal == "fr") this.$store.dispatch("setlanguage", "en");
      else this.$store.dispatch("setlanguage", "fr");
    },
  },
};
</script>

<style>
#card-cvc {
  color: rgb(65, 65, 65);
  margin-bottom: 20px;
  margin-left: 5px;
  width: 188px !important;
}
.container {
  --bs-gutter-x: 0.6rem !important;
}
.v #custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
.divider {
  border-color: white !important;
}
.cardColor {
  background: rgba(255, 255, 255, 0.3) !important;
  border-color: white !important;
}
.pay-btn {
  background: linear-gradient(90deg, #102e49, #b04647);
  height: 40px;
}
.try-btn {
  background: #b04647 !important;
  height: 40px;
}
.close-btn {
  background: #b04647 !important;
  margin-top: 25px;
  margin-right: -10px;
  border-radius: 100%;
}
.pay-btn:hover {
  background: linear-gradient(180deg, #102e49, #b04647);
}
.v-toolbar__content {
  max-height: 25px !important;
}
.v-toolbar--extended {
  max-height: 72px !important;
}
#card-number {
  color: rgb(65, 65, 65);
  margin-bottom: 10px;
}

#card-cvc {
  color: rgb(65, 65, 65);
  margin-bottom: 20px;
}

#card-expiry {
  color: rgb(65, 65, 65);
  margin-bottom: 20px;
}
.form-control {
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0.8rem 0.55rem;
}
.v-toolbar__content {
  padding: 0px !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}
.v-tab {
  font-size: 0.7rem !important;
}

#sandbox {
  float: left;
  color: white;
  margin-left: 50px !important;
  background-color: #ff8c00;
  padding: 4px;
  position: fixed;
  border-radius: 2px;
  top: 0;
  left: 0;
}
@media (max-width: 700px) {
  #sandbox {
    float: left;
    color: white;
    background-color: #ff8c00;
    margin-left: 2px !important;
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
