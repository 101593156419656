<template>
  <v-container fill-height style="max-width: 1000px" class="p-8">
    <div v-if="type_account == 'sandbox'" id="sandbox">Sandbox</div>
    <v-row style="background: white" class="text-center" justify="center">
      <v-col md="6" v-if="!error_message">
        <v-card elevation="0" class="">
          <v-img width="400" :src="paiement_image" class="mx-auto"></v-img>

          <v-chip class="ma-2" color="#b04647" label outlined>
            {{ page_name }}
          </v-chip>

          <v-card-text>
            <div class="text-divider">
              <v-btn
                rounded
                color="#102e49"
                class="text-white"
                @click="show_detail = !show_detail"
                >Details du paiement
                <v-icon v-if="show_detail">mdi-chevron-up-circle</v-icon>
                <v-icon v-if="!show_detail"
                  >mdi-chevron-down-circle</v-icon
                ></v-btn
              >
            </div>
            <div v-if="show_detail">
              <p style="text-align: justify">
                {{ description ?? "" }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="6" style="position: sticky; top: 0">
        <v-card
          style="background-color: #102e49; position: sticky; top: 0"
          elevation="1"
          v-if="!action"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-if="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-if="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>
          <v-btn
            fab
            absolute
            top
            x-small
            right
            depressed
            class="text-white close-btn"
            v-if="!isLoading"
            @click="callParentFunction"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-text v-if="error_message">
            <div
              style="
                font-weight: 800;
                color: #b04647;
                margin-top: 100px;
                margin-bottom: 50px;
              "
            >
              <h6 class="mx-auto">{{ $t("title.invalid_token") }}</h6>
            </div>
            <div style="font-weight: 700">
              <v-icon color="white" size="100px">
                fa-regular fa-face-frown
              </v-icon>
            </div>
          </v-card-text>

          <v-card-text class="mb-10">
            <div class="white--text"></div>
          </v-card-text>

          <v-card v-if="!error_message" flat>
            <v-card-text>
              <v-form v-model="valid" :eager="true" ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        clearable
                        required
                        ref="iu"
                        color="grey"
                        v-model="firstname"
                        :label="$t('fields.firstname')"
                        :rules="[
                          (v) => !!v || $t('validations.required_firstname'),
                        ]"
                        filled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        clearable
                        required
                        color="grey"
                        v-model="lastname"
                        :label="$t('fields.lastname')"
                        filled
                        :rules="[
                          (v) => !!v || $t('validations.required_lastname'),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        color="grey"
                        v-model="email"
                        filled
                        required
                        label="Email"
                        prepend-inner-icon="mdi-email"
                        clearable
                        :rules="[
                          (v) => !!v || $t('validations.required_email'),
                          (v) =>
                            /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.' ']{2,5})$/.test(
                              v
                            ) || $t('validations.valid_email'),
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        color="grey"
                        filled
                        required
                        type="number"
                        :hide-spin-buttons="true"
                        v-model="amount"
                        :label="$t('fields.amount')"
                        clearable
                        :disabled="fixed_amount"
                        prepend-inner-icon="mdi-cash"
                        :rules="[
                          (v) => !!v || $t('validations.required_field'),
                          (v) =>
                            (v && v >= 100) || $t('validations.min_amount'),
                        ]"
                      ></v-text-field>
                    </v-col>

                    <span style="padding: 0px" v-if="fields_list.length > 0">
                      <v-col
                        v-for="(field_item, index) in fields_list"
                        :key="index"
                        cols="12"
                        sm="12"
                      >
                        <v-text-field
                          :rules="[
                            (v) => !!v || $t('validations.required_field'),
                          ]"
                          required
                          color="grey"
                          filled
                          v-model="field_item.value"
                          :label="field_item.placeholder"
                        >
                        </v-text-field>
                      </v-col>
                    </span>

                    <v-col cols="12" sm="12">
                      <v-btn
                        block
                        depressed
                        height="40"
                        class="text-white pay-btn"
                        @click="validate"
                        :loading="isLoading"
                        :disabled="!valid"
                      >
                        {{ $t("btn.continue") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions
              v-if="!error_message"
              class=""
              style="
                font-size: 10px;
                display: block;
                font-weight: 500;
                background-color: #102e49;
              "
            >
              <v-spacer></v-spacer>
              <div class="white--text mx-auto" style="margin: 8px">
                {{ $t("title.bottom_text4") }}
                <v-icon style="font-size: 18px; color: #b04647">
                  mdi-shield-check-outline</v-icon
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-card>

        <!-- waiting card -->
        <v-card
          v-if="action == 'waiting'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-if="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-if="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-card-text>
            <v-img
              style="margin-top: 10px"
              height="150"
              contain
              :src="hand_img"
            ></v-img>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 10px;
                line-height: 2 !important;
              "
            >
              <p class="mx-auto">
                {{ $t("title.help1") }}
              </p>
            </div>
            <div
              style="
                font-size: 20px;
                font-weight: bold;
                color: #b04647;
                line-height: 2 !important;
              "
            >
              <p
                v-if="network == 'mtn'"
                style="
                  background-color: rgb(176, 70, 71, 0.3);
                  border-radius: 5px;
                "
                class="mx-auto"
              >
                {{ $t("title.help_mtn") }}
              </p>
              <p
                v-if="network == 'moov'"
                style="
                  background-color: rgb(176, 70, 71, 0.3);
                  border-radius: 5px;
                "
                class="mx-auto"
              >
                Tapez *855#
              </p>
            </div>

            <div
              style="
                font-weight: 700;
                color: #fff;
                margin-top: 5px;
                margin-bottom: 5px;
                line-height: 2.5;
              "
            >
              <v-progress-circular
                :size="50"
                :width="6"
                color="rgb(176, 70, 71, 1);"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>

        <!-- completed card  -->
        <v-card
          v-if="action == 'completed'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-if="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-if="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-card-text>
            <div
              style="font-weight: 700; margin-top: 50px; margin-bottom: 30px"
            >
              <v-icon color="white" size="70px"> mdi-check-circle</v-icon>
            </div>
            <div
              style="
                font-weight: 900;
                color: white;
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 30px;
              "
            >
              {{ $t("title.success_payment") }}
            </div>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 30px;
                line-height: 2 !important;
                font-size: 20px;
              "
            >
              {{ $t("title.success_message") }}
              <span
                style="
                  font-weight: 900;
                  color: #b04647 !important;
                  font-size: 25px;
                "
              >
                {{ amount }}
              </span>
              {{ $t("title.success_message1") }}
            </div>

            <v-btn
              absolute
              outlined
              color="success"
              depressed
              @click="terminate"
            >
              {{ $t("btn.terminate") }}
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- failed card -->
        <v-card
          v-if="action == 'failed'"
          elevation="15"
          style="background-color: #102e49"
        >
          <v-btn
            absolute
            top
            outlined
            left
            color="transparent"
            depressed
            @click="changeLanguage"
          >
            <flag
              v-if="langLocal == 'en'"
              style="border-radius: 2px"
              iso="fr"
              class="white-text"
            />
            |
            <flag
              v-if="langLocal == 'fr'"
              iso="us"
              style="border-radius: 2px"
              class="white-text"
            />
          </v-btn>

          <v-btn
            fab
            absolute
            topclose
            class="text-white close-btn"
            v-if="!isLoading"
            @click="callParentFunction"
          >
            <i class="fa-solid fa-xmark"></i>
          </v-btn>

          <v-card-text>
            <div
              style="font-weight: 700; margin-top: 50px; margin-bottom: 30px"
            >
              <v-icon color="#b04647" size="80px">fa-solid fa-xmark</v-icon>
            </div>
            <div
              style="
                font-weight: 900;
                color: white;
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 30px;
              "
            >
              {{ $t("title.failed_payment") }}
            </div>

            <div
              v-if="message_failed_payment"
              style="
                font-weight: 900;
                color: #b04647;
                margin-top: 3px;
                margin-bottom: 3px;
                font-size: 15px;
              "
            >
              {{ message_failed_payment }}
            </div>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 30px;
                line-height: 2 !important;
                font-size: 20px;
              "
            ></div>
            <div
              style="
                font-weight: 700;
                color: #ffff;
                margin-top: 10px;
                margin-bottom: 30px;
                line-height: 2 !important;
                font-size: 20px;
              "
            >
              <v-btn outlined class="text-white" color="warning">
                {{ $t("btn.try") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import waiting_img from "../assets/waiting.png";
import i18n from "../i18n";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, minValue, helpers } from "vuelidate/lib/validators";
import paiment_img from "../assets/paiement.png";

const nameRegex = helpers.regex(
  "alphaNum",
  /^(?![0-9]+$)[A-Za-z0-9_ -]{1,30}$/
);

export default {
  name: "PagePayment",
  mixins: [validationMixin],
  validations: {
    firstname: { required, nameRegex },
    lastname: { required, nameRegex },
    email: { required, email },
    amount: {
      required,
      minValue: minValue(100),
    },
  },

  data() {
    return {
      mounted: false,
      valid: false,
      center: "",
      justify: "",
      hide_details: true,
      show_detail: true,
      paiement_image: paiment_img,
      action: null,
      network: null,
      hand_img: waiting_img,
      client_secret: null,
      elements: null,
      closeBtn: false,
      help_message: null,
      code: null,
      error_message: null,
      show_close: true,
      error: false,
      token: null,
      show: true,
      isLoading: false,
      payment_mode: null,
      fixed_amount: false,
      img_src: null,
      phone_icon: "mdi-phone",
      icon: "mdi-card",
      email: null,
      firstname: null,
      lastname: null,
      amount: null,
      idTransact: null,
      description: null,
      return_url_integrator: null,
      message_failed_payment: null,
      amount_customers: null,
      amount_customers_card: null,
      percent: "2%",
      type_account: null,
      page_name: "Page de paiement",
      fields_list: [],
    };
  },

  created() {
    this.token = this.$route.params.token;
    this.getTransactionToken();
  },
  mounted() {
    this.mounted = true;
  },

  computed: {
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.required &&
        errors.push(i18n.t("validations.required_firstname"));
      !this.$v.firstname.nameRegex &&
        errors.push(i18n.t("validations.caracter_firstname"));
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastname.$dirty) return errors;
      !this.$v.lastname.required &&
        errors.push(i18n.t("validations.required_lastname"));
      !this.$v.lastname.nameRegex &&
        errors.push(i18n.t("validations.caracter_lastname"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(i18n.t("validations.valid_email"));
      !this.$v.email.required &&
        errors.push(i18n.t("validations.required_email"));
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required &&
        errors.push(i18n.t("validations.required_amount"));
      !this.$v.amount.minValue && errors.push(i18n.t("validations.min_amount"));
      return errors;
    },
    ...mapGetters(["langLocal", "listPrefix"]),
  },
  watch: {
    email(val) {
      if (val) {
        this.email = val.replace(/ +/g, "");
        this.email = this.email.trim();
      }
    },
    langLocal(val) {
      if (this.error_message) {
        this.error_message = i18n.t("title.error");
      }
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.payment_submit();
      }
    },
    fieldErrors(index) {
      const errors = [];
      if (
        this.fields_list[index].value == null ||
        this.fields_list[index].value == ""
      ) {
        errors.push(i18n.t("validations.required_field"));
      }
      return errors;
    },
    terminate() {
      Print.postMessage("Hello World being called from Javascript code");
    },

    callParentFunction() {
      window.parent.postMessage(
        {
          success: null,
          secured_id: "secured_id_w9r4b8mvk",
          close: true,
          closeWidget: { success: false, reason: "DIALOG DISMISSED" },
        },
        "*"
      );
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    hasQueryParams(url) {
      return url.indexOf("?") !== -1;
    },

    async showMessage(messageText) {
      const messageContainer = this.$refs.carderror;
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.textContent = "";
      }, 10000);
    },

    async getTransactionToken() {
      if (this.token) {
        let paymentInfo = await this.$store.dispatch(
          "getPaymentPageInfo",
          this.token
        );
        if (paymentInfo.success) {
          if (paymentInfo.data.fixedAmount != null) {
            this.fixed_amount = true;
            this.amount = paymentInfo.data.fixedAmount;
          }
          if (paymentInfo.data.imagePagePaiement != null) {
            this.paiement_image = paymentInfo.data.imagePagePaiement;
          }

          this.description = paymentInfo.data.descriptionPagePaiement;
          this.page_name = paymentInfo.data.namePagePaiement;
          this.fields_list = paymentInfo.data.optionPagePaiement;
          this.type_account = paymentInfo.data.compte;
        } else {
          this.error_message = paymentInfo.message;
          this.center = "center";
          this.justify = "center";
        }
      }
    },

    async payment_submit() {
      this.isLoading = true;
      if (
        !this.$v.firstname.$invalid &&
        !this.$v.lastname.$invalid &&
        !this.$v.email.$invalid &&
        !this.$v.amount.$invalid
      ) {
        const data = new FormData();
        data.append("token", this.token);
        data.append("firstname", this.firstname);
        data.append("lastname", this.lastname);
        data.append("email", this.email);
        data.append("amount", this.amount);
        data.append("fields_data", JSON.stringify(this.fields_list));

        let paymentToken = await this.$store.dispatch("getTokenPayment", data);

        if (paymentToken.success) {
          this.$router.push({
            name: "notimes",
            params: { token: paymentToken.data.token },
          });
        } else {
          this.isLoading = false;
          this.error_message = paymentInfo.message;
          this.center = "center";
          this.justify = "center";
        }
      } else {
        this.isLoading = false;
      }
    },
    changeLanguage() {
      if (this.langLocal == "fr") this.$store.dispatch("setlanguage", "en");
      else this.$store.dispatch("setlanguage", "fr");
    },
  },
};
</script>

<style>
.col-12 {
  padding: 4px !important;
}
.text-divider {
  display: flex;
  align-items: center;
  color: #102e49;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-divider::before,
.text-divider::after {
  content: "";
  margin: 10px;
  height: 1px;
  background-color: #102e4938;
  flex-grow: 1;
}

#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}
.disable-events {
  pointer-events: none;
}
#card-error {
  color: red;
}
.divider {
  border-color: white !important;
}
.cardColor {
  background: rgba(255, 255, 255, 0.3) !important;
  border-color: white !important;
}
.pay-btn {
  background: linear-gradient(90deg, #102e49, #b04647);
  height: 40px;
}
.try-btn {
  background: #b04647 !important;
  height: 40px;
}
.close-btn {
  background: #b04647 !important;
  margin-top: 25px;
  margin-right: -10px;
  border-radius: 100%;
}
.pay-btn:hover {
  background: linear-gradient(180deg, #102e49, #b04647);
}
.v-toolbar__content {
  max-height: 25px !important;
}
.v-toolbar--extended {
  max-height: 72px !important;
}
#card-number {
  color: rgb(65, 65, 65);
  margin-bottom: 10px;
}

#card-cvc {
  color: rgb(65, 65, 65);
  margin-bottom: 20px;
}

#card-expiry {
  color: rgb(65, 65, 65);
  margin-bottom: 20px;
}
.form-control {
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0.8rem 0.55rem;
}
.v-toolbar__content {
  padding: 0px !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}
.v-tab {
  font-size: 0.7rem !important;
}

#sandbox {
  float: left;
  color: white;
  margin-left: 50px !important;
  background-color: #ff8c00;
  padding: 4px;
  position: fixed;
  border-radius: 2px;
  top: 0;
  left: 0;
}
@media (max-width: 700px) {
  #sandbox {
    float: left;
    color: white;
    background-color: #ff8c00;
    margin-left: 2px !important;
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
